
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import {
  required,
  maxLength,
  minLength,
  between,
  integer,
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { UFliterals } from "@/utils/literals";
import { ITipGroup } from "@/schemas/ITipGroup";
import { ITip } from "@/schemas/ITip";
import { Scope } from "@/schemas/Enums";
import { UFFormValidationMessage } from "@/utils/literals/forms";

@Component({
  name: "TipGroupForm",
  components: { draggable },
  mixins: [validationMixin],
  validations: {
    tipGroup: {
      title: {
        required,
        maxLength: maxLength(100),
        minLength: minLength(5),
      },
      description: {
        required,
        maxLength: maxLength(1000),
        minLength: minLength(10),
      },
      scope: { required },
      tips: {
        required,
        minLength: minLength(1),
        $each: {
          content: {
            required,
            maxLength: maxLength(200),
            minLength: minLength(5),
          },
          display_at: {
            integer,
            between: between(0, 100),
          },
          highlight_begin_at: {
            integer,
            between: between(0, 100),
          },
          highlight_end_at: {
            integer,
            between: between(0, 100),
          },
          remove_at: {
            integer,
            between: between(0, 100),
          },
          completion_display_at: { integer, between: between(0, 100) },
          completion_remove_at: { integer, between: between(0, 100) },
          completion_highlight_at: { integer, between: between(0, 100) },
          completion_highlight_end_at: { integer, between: between(0, 100) },
        },
      },
    },
  },
})
export default class TipGroupForm extends Vue {
  @Prop({ type: String, required: false, default: UFliterals.create })
  readonly submitText: string | undefined;
  @Prop({ type: Function, required: false })
  readonly dataHandler: CallableFunction | undefined;
  @Prop({ type: Boolean, required: false, default: false })
  readonly readOnly: boolean | undefined;
  @Prop({ type: Object, required: false })
  readonly initialTipGroup: ITipGroup | undefined;

  private tipGroup: ITipGroup = {
    title: "",
    description: "",
    scope: Scope.Group,
    tips: [],
  };

  data(): any {
    return {
      dialogClose: false,
      dialogClose2: false,
      dialog: "",
      creation: true,
      // userList: [],
    }
  }

  @Watch("initialTipGroup")
  initialActivityChanged(val: ITipGroup): void {
    if (Object.keys(this.initialTipGroup).length) {
      this.creation=false
    }
    else{
      this.creation=true
    }
    this.renewTipGroup(val);
  }

  created(): void {
    this.creation=true
    if (this.initialTipGroup) {
      if (Object.keys(this.initialTipGroup).length) {
        this.tipGroup = { ...this.initialTipGroup };
        this.creation=false
      }
    }
  }

  get titleErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.tipGroup.title?.$dirty) return errors;
    !this.$v.tipGroup.title.minLength &&
      errors.push(UFFormValidationMessage.minLengthChar(5));
    !this.$v.tipGroup.title.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthChar(100));
    !this.$v.tipGroup.title.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get descriptionErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.tipGroup.description?.$dirty) return errors;
    !this.$v.tipGroup.description.minLength &&
      errors.push(UFFormValidationMessage.minLengthChar(10));
    !this.$v.tipGroup.description.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthChar(1000));
    !this.$v.tipGroup.description.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  getTipContentErrors(index: number): string[] {
    const errors: string[] = [];
    if (!this.$v.tipGroup.tips?.$each.$iter[index]?.content.$dirty)
      return errors;
    !this.$v.tipGroup.tips.$each.$iter[index]?.content.minLength &&
      errors.push(UFFormValidationMessage.minLengthChar(5));
    !this.$v.tipGroup.tips.$each.$iter[index]?.content.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthChar(200));
    !this.$v.tipGroup.tips.$each.$iter[index]?.content.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get scopeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.tipGroup.scope?.$dirty) return errors;
    !this.$v.tipGroup.scope.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get scopeSelectItems(): Record<string, any>[] {
    let selectValues: Record<string, any>[] = [];
    for (const value of Object.values(Scope)) {
      selectValues.push({
        text: this.getUFLabel(value),
        value: value,
      });
    }
    return selectValues;
  }

  getUFLabel(key: string): string {
    return UFliterals[key];
  }

  addTip(): void {
    let newTip: ITip = {
      content: "",
    };
    if (!this.tipGroup.tips) {
      Vue.set(this.tipGroup, "tips", [newTip]);
    } else {
      Vue.set(this.tipGroup.tips, this.tipGroup.tips?.length, newTip);
    }
  }

  removeTip(index: number): void {
    if (this.tipGroup.tips) {
      let elementToRemove = this.tipGroup.tips[index];
      if (elementToRemove) {
        if (elementToRemove.id) {
          // The element is already saved, so we'll have to remove it
          this.$store.dispatch("tip/deleteTip", {
            id: elementToRemove.id,
            next: () => {
              this.tipGroup.tips?.splice(index, 1);
            },
          });
          this.tipGroup.tips?.splice(index, 1);
        } else {
          this.tipGroup.tips.splice(index, 1);
        }
      }
    }
  }

  handleSubmit(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      if (this.dataHandler) {
        this.dataHandler(this.tipGroup);
      }
    }
  }

  clear(): void {
    this.$v.$reset();
    this.tipGroup = {
      title: "",
      description: "",
      scope: Scope.Group,
      tips: [],
    };
  }

  renewTipGroup(value: ITipGroup): void {
    this.tipGroup = Object.assign({}, value);
  }
}
