import { render, staticRenderFns } from "./Account.vue?vue&type=template&id=351eb78a&scoped=true&"
import script from "./Account.vue?vue&type=script&lang=ts&"
export * from "./Account.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "351eb78a",
  null
  
)

export default component.exports