
import { mapActions, mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import RegistrationForm from "../components/authentication/RegistrationForm.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";
import { UFFormValidationMessage } from "@/utils/literals/forms";
import { ref } from 'vue';
import message from "@/repository/message";
import Logo from "@/components/identity/Logo.vue";
import HomeTitle from "@/components/identity/HomeTitle.vue";
import SequenceList from "@/components/sequence/SequenceList.vue";
import AdminBoard from "@/components/admin/AdminBoard.vue";
import IconActionCards from "@/components/cta/IconActionCards.vue";
import { IIconActionCard } from "@/schemas/IIconActionCard";

@Component({
  name: "Account",
  components: { IconActionCards, AdminBoard, SequenceList, HomeTitle, Logo },
  computed: {
    ...mapState(["isAuthenticated"]),
    ...mapGetters("user", ["canEnterAdmin"]),
  },

})
export default class Account extends Vue {
  @Prop({ type: String, required: false, default: null })
  token: string | undefined;


  data(): any {
    return {
      CurrentEmail:""
    }
  }

  created(){
    if(!this.isAuthenticated && !this.canEnterAdmin){
      this.$router.push({ name: 'login' })
    }
    if (this.username === "" && this.$store.state.isAuthenticated) {
      this.$store.dispatch("user/fetchCurrentUser");
    }
    if (this.CurrentEmail === "" && this.$store.state.isAuthenticated) {
      this.$store.dispatch("user/fetchUserEmail",
      {
          next: (email) => {this.CurrentEmail=email},
      });
    }
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }


}
